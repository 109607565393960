<template>
  <div>
    <label v-sanitize-html="field.label"></label>
    <!-- Draggable Word Bank -->
    <v-row class="word-bank">
      <v-col
        cols="12"
        md="5"
        @drop.prevent="dropWordIntoRemainingWords"
        @dragover.prevent
      >
        <v-sheet :class="{ column: field.column }">
          <v-chip-group :value="remainingWords" column>
            <v-chip
              v-for="(word, index) in remainingWords"
              :key="index"
              v-sanitize-html="word.option"
              :draggable="true"
              @dragstart="dragStart(word)"
              @dragend="dragEnd"
              :ripple="false"
            >
            </v-chip>
          </v-chip-group>
        </v-sheet>
      </v-col>

      <!-- Droppable Buckets -->
      <v-col cols="12" md="6">
        <v-row>
          <v-col
            v-for="[bucket] of reactiveResponses"
            :key="bucket.questionId"
            cols="12"
          >
            <validation-provider
              ref="bucketValidatorRef"
              :name="`bucket-${bucket.questionId}`"
              :rules="rules"
              v-slot="{ errors }"
            >
              <v-card
                :key="bucket.questionId"
                class="my-3"
                outlined
                height="150px"
                @drop.prevent="dropWord(bucket)"
                @dragover.prevent
              >
                <v-card-subtitle
                  v-sanitize-html="bucket.label"
                ></v-card-subtitle>

                <v-row class="px-6">
                  <v-chip-group :value="bucket.selections" column>
                    <v-chip
                      v-for="(word, wordIndex) in reactiveResponses.get(bucket)"
                      :key="wordIndex"
                      v-sanitize-html="word.option"
                      :draggable="true"
                      @dragstart="dragStartFromBucket(word, bucket)"
                      @dragend="dragEnd"
                      :ripple="false"
                      class="ma-1"
                    >
                    </v-chip>
                  </v-chip-group>
                </v-row>
              </v-card>
              <span class="error--text text-caption">{{ errors[0] }}</span>
            </validation-provider>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ["field", "value", "readonly", "validations"],
  data() {
    return {
      draggedWord: null,
      draggedFromBucket: null,
      responses: new Map(),
      remainingWords: [],
      trigger: 0,
    };
  },
  mounted() {
    // initialize remainingWords as a copy of field.options
    this.remainingWords = [...this.field.options];

    // initialize responses as a Map where key is bucket, value is selected responses or empty array
    for (let i = 0; i < this.value.length; i++) {
      this.responses.set(this.value[i], this.value[i].selections || []);
      // remove selected responses from remainingWords
      this.remainingWords = this.remainingWords.filter((item) => {
        return !this.value[i].selections
          .map((x) => x.questionOptionId)
          .includes(item.questionOptionId);
      });
    }

    this.trigger++;
  },
  computed: {
    rules() {
      return {
        ...this.validations,
      };
    },
    reactiveResponses() {
      // use to trigger computed property to update because maps are not supported in vue2 reactivity
      this.trigger;
      return new Map(this.responses);
    },
  },
  methods: {
    dragStart(word) {
      this.draggedWord = word;
      this.draggedFromBucket = null;
    },
    dragStartFromBucket(word, bucket) {
      this.draggedWord = word;
      this.draggedFromBucket = bucket;
    },
    dragEnd() {
      this.draggedWord = null;
    },
    dropWordIntoRemainingWords() {
      if (this.draggedWord) {
        // Remove the word from original bucket
        if (this.draggedFromBucket !== null) {
          const arr = this.responses.get(this.draggedFromBucket);
          const index = arr.indexOf(this.draggedWord);
          if (index > -1) {
            arr.splice(index, 1);
            this.responses.set(this.draggedFromBucket, arr);
            this.trigger++;
          }

          // save response
          this.$emit("fieldChange", {
            questionId: this.draggedFromBucket.questionId,
            label: this.draggedFromBucket.label,
            selections: this.responses.get(this.draggedFromBucket),
          });
        }

        // Add the word to the word bank if not already present
        if (!this.remainingWords.includes(this.draggedWord)) {
          this.remainingWords.push(this.draggedWord);
          this.trigger++;
        }

        // validate
        this.$refs.bucketValidatorRef
          .find((x) => x.name == `bucket-${this.draggedFromBucket.questionId}`)
          .validate();

        this.draggedWord = null;
        this.draggedFromBucket = null;
      }
    },
    dropWord(bucket) {
      if (this.draggedWord) {
        // Remove the word from the word bank if it's dragged from there
        if (this.draggedFromBucket === null) {
          this.remainingWords = this.remainingWords.filter(
            (word) =>
              word.questionOptionId !== this.draggedWord.questionOptionId
          );
        }

        // Remove the word from original bucket if it's dragged from a different bucket
        if (
          this.draggedFromBucket !== null &&
          this.draggedFromBucket.questionId !== bucket.questionId
        ) {
          const arr = this.responses.get(this.draggedFromBucket);
          const index = arr.indexOf(this.draggedWord);
          if (index > -1) {
            arr.splice(index, 1);
            this.responses.set(this.draggedFromBucket, arr);
            this.trigger++;
          }

          // save response
          this.$emit("fieldChange", {
            questionId: this.draggedFromBucket.questionId,
            label: this.draggedFromBucket.label,
            selections: this.responses.get(this.draggedFromBucket),
          });

          // validate
          this.$refs.bucketValidatorRef
            .find(
              (x) => x.name == `bucket-${this.draggedFromBucket.questionId}`
            )
            .validate();
        }

        // Add the word to the new bucket if not already present
        if (
          !this.responses.get(bucket) ||
          !this.responses.get(bucket).includes(this.draggedWord)
        ) {
          this.responses.get(bucket).push(this.draggedWord);
          this.trigger++;
        }

        // save response
        this.$emit("fieldChange", {
          questionId: bucket.questionId,
          label: bucket.label,
          selections: this.responses.get(bucket),
        });

        // validate
        this.$refs.bucketValidatorRef
          .find((x) => x.name == `bucket-${bucket.questionId}`)
          .validate();

        this.draggedWord = null;
        this.draggedFromBucket = null;
      }
    },
  },
};
</script>

<style>
.column .v-chip-group--column .v-slide-group__content {
  display: block;
}
.column .v-chip-group--column .v-chip {
  float: left;
  clear: both;
}
.word-bank .v-chip-group .v-chip {
  height: auto !important;
  white-space: normal;
  font-size: 12px;
}
</style>
